.block-image {
    &.is-accordion .accordion {
        &:after {
            width: columnWidth(5);
    
            @include breakpoint(por) {
                width: 100%;
            }
        }

        .accordion-label  {
            position: relative;

            .accordion-caret {
                position: absolute;
                width: $lnh-l;
                left: calc(#{columnWidth(5)} - #{$lnh-l} - 3px);
                margin-left: 0;
    
                @include breakpoint(sm) {
                    position: relative;
                    left: 0;
                }
            }
        }
    }
}

.block-image, 
.block-video,
.block-gallery {
    &.is-accordion .accordion-body > div {
        position: relative;
        padding-bottom: $lnh-m;
        padding-top: 5px;
    }
}

@import 'blocks/block-text';
@import 'blocks/block-textarea';
@import 'blocks/block-headline';
@import 'blocks/block-grid';
@import 'blocks/block-publications';
@import 'blocks/block-gallery';
@import 'blocks/block-video';
@import 'blocks/block-archive';
@import 'blocks/block-form';
@import 'blocks/block-references';
@import 'blocks/block-downloads';
@import 'blocks/block-consent';
@import 'blocks/block-button';