.block-grid {

    & > .grid > .row {
        @include breakpoint(por) {
            margin-left: -$spc-v-s-m / 2;
            margin-right: -$spc-v-s-m / 2;
        }
    }

    .rel-col-2 {
        padding-bottom: $gutter;

        @include breakpoint(por) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 $spc-v-s-m / 2;
            padding-bottom: $spc-v-s-m / 2;
        }

        @include breakpoint(1600px,0) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .grid-image {
        margin-bottom: $lnh-s / 4;
    }

    a[download] {
        position: relative;
        display: block;
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,3,226,0);
            background-image: none;

            border-radius: 15px;
            z-index: 1;

            margin-top: 0;

            @include breakpoint(por) {
                border-radius: 10px;
            }
        }

        &:hover{
            img {
                filter: grayscale(1);
            }
            
            &:before {
                background-color: rgba(0,3,226,.5);
                mix-blend-mode: screen;
            }
        }
    }
}