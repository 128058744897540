.block-textarea {
    img {
        width: inherit;
        max-width: 100%;

        &:not([width]) {
            width: 100%;
        }
    }

    figure {
        margin-bottom: $lnh-m;
        display: inline-block;
        vertical-align: top;
    }
}