.block-publications {
    ul li {
        display: flex;

        &:before {
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            margin-right: .35em;
            margin-top: 0;
            background-color: $blue;
            border-radius: 2em;
            transform: translateY(.3em);
        }

        p {
            margin-bottom: 0;
        }
    }
}