.block-gallery {
    position: relative;
    overflow: hidden;

    &.is-accordion {
        .accordion,
        .accordion-body {
            overflow: visible;
        }

        .accordion:after {
            width: columnWidth(5);

            @include breakpoint(por) {
                width: 100%;
            }
        }

        .accordion-label  {
            position: relative;

            .accordion-caret {
                position: absolute;
                width: $lnh-l;
                left: calc(#{columnWidth(5)} - #{$lnh-l} - 3px);
                margin-left: 0;
    
                @include breakpoint(sm) {
                    position: relative;
                    left: 0;
                }
            }
        }
    }

    .swiper-container {
        overflow: visible;
    }

    @import './../../../../modules/swiper.scss'
}