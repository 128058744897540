.block-archive {
    ul {
        margin-bottom: $lnh-s / 2;

        @include breakpoint(por) {
            margin-bottom: $lnh-s-m;
        }

        p {
            margin-bottom: 0;
        }
    }

    .teaser.archive-item {

        .row .col-2 {
            @include breakpoint(por) {
                margin-bottom: $lnh-s-m;
            }
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: $lnh-m / 2;
            margin-bottom: $lnh-m / 2;
            border-bottom: 1px solid black;

            @include breakpoint(por) {
                padding-bottom: $lnh-m-m / 1.25;
                margin-bottom: $lnh-m-m / 2;
            }
        }

        h2 {
            @include fontformat($fmt-l);
            font-weight: bold;
            letter-spacing: 0;
    
            @include breakpoint(por) {
                @include fontformat($fmt-l-m);
            }
        }
    
        h3 {
            // @include fontformat($fmt-l);
            font-weight: bold;
            letter-spacing: 0;
        }

        a.link {

        }
    }
}