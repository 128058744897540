.block-form {
    padding-bottom: $lnh-l * 2;

    &.submitted {
        form {
            opacity: 0;
        }
        .messagebox {
            opacity: 1;
        }
    }

    form {
        transition: opacity .5s;

        & > div {
            position: relative;
            margin: $lnh-s / 2 0;
        }
    }

    input:not([type="radio"]):not([type="checkbox"]), 
    textarea {
        display: block;
        width: 100%;

        box-sizing: border-box;

        border-radius: 15px;
        background-color: rgba(0, 3, 226, 0.29);
        padding: 5px 15px;

        resize: none;

        @include breakpoint(por) {
            border-radius: 10px;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        top: 8px;
        left: 8px;
        opacity: 0;
        cursor: pointer;
        height: 1px;
        width: 1px;
        z-index: -1;
    }

    label {
        p {
            display: inline;
            margin-bottom: 0;
        }
    }

    .input-checkbox,
    .input-radio {
        label {
            display: inline-flex;
            align-items: center;

            &:before {
                content: '';
                display: block;
                width: 14px;
                height: 14px;

                flex: 0 0 auto;

                border-radius: 2em;
                border: 1px solid black;

                margin-right: .5em;
            }
        }
    }

    p.radiogroup-label {
        margin-bottom: 0;
    }

    .input-radio.salutation {
        display: flex;

        p.radiogroup-label {
            display: inline-block;
            width: 25%;
    
            & ~ div {
                padding-right: $lnh-s;

                @include breakpoint(por) {
                    padding-right: $lnh-s-m;
                }
            }
        }
    }

    .input-option {
        label {
            cursor: pointer;

            &:hover {
                &:before {
                    background-color: $blue;
                    border-color: $blue;
                }
            }
        }

        input:checked {
            & + label:before {
                background-color: $blue;
                border-color: $blue;
            }
        }
    }

    // button {
    //     border-radius: 2em;
    //     background-color: $blue;
    //     color: white;
    //     line-height: 37px;
    //     padding: 0 1em;
    //     font-weight: bold;
    // }

    .messagebox {
        transition: opacity .5s;

        .error {
            display: none;
        }

        &.error {
            .error {
                display: block;
            }
            .success {
                display: none;
            }
        }
    }

    .form-footer {
        display: flex;
        justify-content: space-between;

        margin-top: $lnh-s;
    }
}