.swiper-container {
    margin-left: -$gutter / 2;

    @include breakpoint(por) {
        margin-left: 0;
    }

    &:hover {
        div[class^=swiper-button] {
            opacity: 1;
        }
    }

    .swiper-slide {
        padding: 0 $gutter / 2;
    
        width: auto;
    
        @include breakpoint(por) {
            padding: 0;
        }
    
        figure {
            width: min-content // figacption width as img
        }
    
        img {
            height: 500px;
            width: auto;
    
            @include breakpoint(por) {
                height: 200px;
            }
        }
    }

    div[class^="swiper-button"] {
        width: 20%;
        height: 500px;
        top: $spc-v-s;

        opacity: 0;
        transition: opacity .25s;

        mix-blend-mode: difference;

        @include breakpoint(por) {
            height: 200px;
            top: $lnh-m-m;

            opacity: 1;
        }

        &:after {
            content: none;
        }

        svg path {
            fill: $blue-complementary;
        }
    }

    .swiper-button-next {
        right: 0;
        justify-content: flex-end;
        padding-right: $spc-v-s;

        @include breakpoint(por) {
            padding-right: $spc-v-s-m / 2;
        }
    }

    .swiper-button-prev {
        left: 0;
        justify-content: flex-start;
        padding-left: $spc-v-s;

        @include breakpoint(por) {
            padding-left: $spc-v-s-m / 2;
        }

        svg {
            transform: rotate(180deg);
        }
    }

    .swiper-button-disabled {
        opacity: 0 !important;
    }
}