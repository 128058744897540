@import "./../../modules/variables";
@import "./../../vendors/functions";
@import "./../../vendors/mixins";

main {

    section[class^=block] {
        margin-top: $lnh-m;

        @include breakpoint(por) {
            margin-top: $lnh-m-m;
        }

        &:first-of-type {
            margin-top: 0;
        }

        &.is-accordion {
            & + .is-accordion {
                margin-top: 0;
            }
        }
    }

    .content-title {
        margin-bottom: $spc-v-l;

        @include breakpoint(por) {
            margin-bottom: $spc-v-l-m;
        }

        -ms-hyphens: none;
        -moz-hyphens: none;
        -webkit-hyphens: none;
        hyphens: none;
    }

    ul, 
    ol {
        list-style: none;

        li {
            display: flex;
            // flex-wrap: wrap;

            margin-bottom: .25em;

            ul {
                padding-left: 1em;
            }

            p {
                margin-bottom: 0;
                flex: 1;
            }
        }
    }

    ul {
        li:before {
            content: '';
            width: 0.5em;
            height: 0.5em;
            
            background-color: black;
            border-radius: 2em;
            
            flex: 0 0 auto;
            
            margin-top: .5em;
            margin-right: .5em;
        }
    }

    ol {
        counter-reset: section;

        li:before {
            counter-increment: section; 
            content: counter(section);

            width: .5em;
            height: .5em;
            
            flex: 0 0 auto;

            margin-right: .5em;
        }
    }

    @import 'page/blocks'
}

@import 'page/page-aside';
@import 'page/social-sharing';