.social-sharing {
    padding-top: $lnh-l;

    @include breakpoint(por) {
        padding-top: $lnh-l-m;
    }

    &.open {
        button {
            background-color: $blue;
            color: white;
            z-index: 1;
        }

        .share-options {
            display: block;
        }
    }

    .sharing-wrapper {
        display: flex;
    }

    button {
        font-weight: normal;
        // line-height: 1em;
        // height: 24px;

        margin-right: 3px;

        background-color: white;
        border: 1px solid $blue;
        color: blue;
    }

    a {
        display: inline-block;
        margin-left: 5px;
        margin-top: -1px;
        line-height: 0;
        
        img {
            width: 24px;//$lnh-s;//1em;
            height: 24px;//$lnh-s;//1em;
            line-height: 0;

            transform: translateY(1px);

            // @include breakpoint(por) {
            //     transform: translateY(1px);
            //     width: $lnh-s-m * 1.2;//1em;
            //     height: $lnh-s-m * 1.2;//1em;
            // }
        }
    }

    .share-options {
        height: 1em;
        display: none;
    }
}