.block-references {

    .teaser.border-bottom:last-of-type:after {
        display: none
    }

    &.page-team {
        .accordion-body[style^='height'] {
            overflow: visible;
        }
    }
}