.block-button {
    a {
        display: block;
        width: 100%;

        text-align: center;
        line-height: $lnh-s;
        font-weight: bold;

        padding: .25em 1em;
        margin-bottom: $lnh-m / 2;
    }
}