/// TYPOGRAPHY

$fnt: "Pangea", Helvetica, Arial, sans-serif;

/* Helvetica */

$fnt-xl: 48px;
$lnh-xl: 48px;
$lsp-xl: -0.04em;

$fmt-xl: $fnt-xl, $lnh-xl, $lsp-xl;

$fnt-xl-m: 32px;
$lnh-xl-m: 36px;
$lsp-xl-m: -0.04em;

$fmt-xl-m: $fnt-xl-m, $lnh-xl-m, $lsp-xl-m;

$fnt-l: 32px;
$lnh-l: 36px;
$lsp-l: -0.04em;

$fmt-l: $fnt-l, $lnh-l, $lsp-l;

$fnt-l-m: 22px;
$lnh-l-m: 24px;
$lsp-l-m: -0.04em;

$fmt-l-m: $fnt-l-m, $lnh-l-m, $lsp-l-m;

$fnt-m: 20px;//24px;
$lnh-m: 30px;//34px;
$lsp-m: -0.04em;

$fmt-m: $fnt-m, $lnh-m, $lsp-m;

$fnt-m-m: 16px;//18px;
$lnh-m-m: 22px;//24px;
$lsp-m-m: -0.04em;

$fmt-m-m: $fnt-m-m, $lnh-m-m, $lsp-m-m;

$fnt-s: 18px;//20px;
$lnh-s: 26px;//28px;
$lsp-s: -0.04em;

$fmt-s: $fnt-s, $lnh-s, $lsp-s;

$fnt-s-m: 15px;
$lnh-s-m: 19px;
$lsp-s-m: -0.04em;

$fmt-s-m: $fnt-s-m, $lnh-s-m, $lsp-s-m;

$fnt-xs: 16px;//18px;
$lnh-xs: 22px;//30px;
$lsp-xs: -0.02em;

$fmt-xs: $fnt-xs, $lnh-xs, $lsp-xs;

$fnt-xs-m: 16px;
$lnh-xs-m: 22px;
$lsp-xs-m: -0.02em;

$fmt-xs-m: $fnt-xs-m, $lnh-xs-m, $lsp-xs-m;

/// COLORS

$black: rgb(0, 0, 0);
$grey: #6D6D6D;
$blue: #0003e2;
$blue-complementary: #E2DF00;

/// GRID

$gutter: 20px;

$colcount: 6;
$colwidth: 100% / $colcount;
// $colwidth: calc((75vw - #{$gutter * 2}) / $colcount);

/// SPACES

$spc-v-l: 60px;
$spc-v-m: 29px;
$spc-v-s: 20px;

$spc-v-s-m: 14px;
$spc-v-m-m: 1.7vw;
$spc-v-l-m: 3.2vw;

$header-h: 130px;
$header-h-m: 62px;

$footer-h: 117px;

$aside-h: 40px;

$mobileBar: 10px;

/// BREAKS

$navBreak: 1290px;
$screen-xs-max: 562;
$screen-sm-max: 768px;
$screen-md-max: 1200px;
$screen-lg-min: 1440px;
