.block-text {

    @include breakpoint(por) {
        div[class^="col"] {
            margin-bottom: 0;
        }

        .col-1 {
            display: none
        }
    }

    // p,
    // ul {
    //     &:last-child {
    //         margin-bottom: 0
    //     }
    // } 
}