.block-video {
    .video-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;

        &.play {
            .video-poster,
            .video-consent {
                display: none;
            }
        }

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-poster {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .play-button {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                cursor: pointer;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: $lnh-m * 3;
                    height: $lnh-m * 3;
                    transform: translate(-50%,-50%);

                    @include breakpoint(por) {
                        width: $lnh-m-m * 3;
                        height: $lnh-m-m * 3;
                    }
                }
            }
        }

        .dialog.video {
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            height: auto;

            background-color:rgb(179,179,246);
            border: 0;
            color: black;
        }

        iframe,
        .video-poster img {
            border-radius: 15px;

            @include breakpoint(por) {
                border-radius: 10px;
            }
        }
    }

    // &.is-accordion {
    //     // .accordion,
    //     // .accordion-body {
    //     //     overflow: visible;
    //     // }

    //     .accordion-body .grid {
    //         position: relative;
    //         padding-bottom: $lnh-m;
    //         // padding-top: 5px;
    //     }
    // }
}