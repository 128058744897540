aside,
.content .mobile {

    @include breakpoint(por) {
        &.fnt-s {
            @include fontformat($fmt-m-m);
        }

        .aside-collapse.show {
            padding-top: $lnh-m-m !important;
        }
    }

    .page-context {
        * {
            display: inline;
        }

        .icon svg {
            transform: translateY(.25rem)
        }

        @include breakpoint(por) {
            @include fontformat($fmt-l-m);
        } 
    }

    .aside-item {
        margin-bottom: $lnh-s;

        @include breakpoint(por) {
            margin-bottom: $lnh-s-m;
        }
    }

    .aside-item.filter {
        a {
            text-decoration: none;
            color: black;

            &:hover {
                color: $blue;
            }
        }
    }

    .aside-item.image {
        width: 80%;
        
        figure.ratio {
            border-radius: 0;
        }

        img {
            width: auto;
            border-radius: 0;
        }
    }
}