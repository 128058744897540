.block-consent {
    margin-bottom: $lnh-m * 2;

    .cookie-control {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: $spc-v-s / 2;
    }

    button.switch {
        position: relative;
        display: inline-block;
        width: 39px;//$lnh-m * 2;
        height: 20px;//$lnh-m;

        background-color: transparent;
        
        &[aria-checked="true"] {
            // &:focus .slider {
            //     box-shadow: 0 0 1px #2196F3;
            // }
    
            .slider {
                border-color: $blue;

                &:before {
                    background-color: $blue;
                    transform: translateX(19px);
                }
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid grey;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;//$lnh-m;
        width: 20px;//$lnh-m;//26px;
        left: 0;//4px;
        top: -1px;//4px;
        background-color: grey;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .switch-desc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        padding: 0px 3px;

        display: flex;
        align-items: center;

        // .desc {
        //     font-family: $fnt2;
        //     font-size: 9px;
        //     font-weight: 500;
        //     text-transform: uppercase;
        //     line-height: 1em;
        //     z-index: 1;
        //     flex: 1;
        //     color: $grey4;
        //     text-align: center;
        //     padding-top: 2px;
        // }

        .desc.on {
            color: grey;
        }

        .desc.off {
            color: $black;
        }
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}